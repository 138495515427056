import React from 'react';
import NetmakerLogo from '@/assets/NetmakerLogo.svg';
import NetmakerLogoSm from '@/assets/NetmakerLogoSm.svg';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { useBranding } from '@/utils/Utils';
import { useStore } from '@/store/store';

interface LogoBlockProps {
  isSidebarCollapsed: boolean;
  onToggleCollapse: () => void;
}

const LogoBlock: React.FC<LogoBlockProps> = ({ isSidebarCollapsed, onToggleCollapse }) => {
  const logoUrl = isSidebarCollapsed ? NetmakerLogoSm : NetmakerLogo;

  return (
    <div className={`relative flex items-center ${isSidebarCollapsed && 'justify-center'} w-full py-4 pl-5 pr-4`}>
      <img src={logoUrl} alt="Logo" className="h-7" />
      {isSidebarCollapsed ? (
        <div className="absolute hidden p-2 cursor-pointer -right-4 md:block" onClick={onToggleCollapse}>
          <ArrowRightCircleIcon className="shadow-xl size-5 text-button-outline-text-default" />
        </div>
      ) : (
        <div className="absolute hidden p-2 cursor-pointer -right-4 md:block" onClick={onToggleCollapse}>
          <ArrowLeftCircleIcon className="shadow-xl size-5 text-button-outline-text-default" />
        </div>
      )}
    </div>
  );
};

export default LogoBlock;
